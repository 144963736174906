<template>
  <main style="background-color: #f5f5f5;">
    <!-- BANNER -->
    <div
      id="banner"
      :style="{
        'background-image':
          'url(' +
          require('@/assets/img/canaleselectronicos/appmovil.jpg') +
          ')'
      }"
    >
      <div class="texto-banner">
        <h2 class="text-black text-3xl font-bold">App-Movil</h2>
        <p class="text-black-100">Home / App-Movil</p>
      </div>
    </div>

    <!-- SECTION TRANSPARENCIA -->
    <section class="py-10">
      <!-- TABLE -->
      <div
        class="container mx-auto w-full md:w-3/4 p-4 md:p-10 bg-white rounded "
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class="bg-red-700 uppercase text-white rounded text-xl p-1 mb-6">
          <div class="flex justify-between mx-6 md:mx-20">
            <span>DETALLE</span>
            <span>Ver</span>
          </div>
        </div>
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              MANUAL DE USUARIO APP-MOVIL
            </h5>
          </div>
          <div class="right">
            <a
              href="https://procesostres.crediya.fin.ec/images/docs/manualcrediyabancaweb1.pdf"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
        <div class="container flex justify-between px-2 md:px-12 py-1">
          <div class="left flex">
            <img
              src="@/assets/img/transparency/icono.png"
              alt="icono crediya"
              class="mr-2"
              style="height:60px; margin: -15px"
            />
            <h5 class="uppercase text-lg gris">
              MANUAL DE USUARIO BANCA WEB
            </h5>
          </div>
          <div class="right">
            <a
              href="https://procesostres.crediya.fin.ec/images/docs/manualcrediyamovilv1.pdf"
              target="_blank"
              class="container bg-gray-100 px-10 rounded hover:bg-gray-300 hover:text-gray-500 cursor-pointer py-1"
            >
              <font-awesome-icon :icon="['fas', 'eye']" class="gris " />
            </a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "WorkWithUs"
};
</script>

<style scoped>
#banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 350px;
  width: 100%;
  position: relative;
}
.texto-banner {
  position: absolute;
  left: 10%;
  top: 40%;
}
.texto-principal {
  position: absolute;
  left: 10%;
  top: 40%;
}
</style>
